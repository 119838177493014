import React from "react";
import tend from "../assets/tend.png";
import pickup from "../assets/pickup.PNG";
import video from "../assets/projects.mp4";

function Projects() {
    return (
        <div
            className="border-4 border-double p-4"
            style={{ width: `calc(100vw - 22rem)` }}
        >
            <h1 className="text-2xl pt-5 font-bold w-100">Projects</h1>
            <h2 className="text-xl pt-8 font-semibold w-100 underline">
                Cornell Projects
            </h2>
            <p className="pt-2 w-1/2">
                Here Are some of the cool projects I have done during my time at
                Cornell. Most of them have dedicated github pages so be sure to
                check those out if you want to learn more!
            </p>
            <hr className="my-8 rounded h-8 solid" />
            <h3 className="text-l pt-2 font-semibold w-100 underline">
                Autonomous Robot
            </h3>
            <div className="flex">
                <p className="pt-2 w-1/2">
                    This is my Intelligent Physical Systems (ECE 3400) robot.
                    Throughout the semester, we built a robot that was capable
                    of centering itself using ultrasonic sensors, be able to
                    determine the frequency of sounds that are being played, and
                    use infrared light sensors to find treasures in a maze. The
                    robot used an Arduino Nano, so we learned a lot about C
                    syntax throughout the semester. It was really interesting to
                    work on one project for a whole semester as I had not been
                    exposed to that kind of course yet. Below is a GitHub pages
                    website that I created to document my progress.
                    <br />
                    <br />
                    <a
                        href="https://pages.github.coecis.cornell.edu/dmr284/ece3400/"
                        style={{ color: "rgb(59 130 246)" }}
                        rel="noreferrer"
                        target="_blank"
                    >
                        GitHub Pages for ECE 3400 Project
                    </a>
                </p>
                <div className="w-1/5 translate-x-40">
                    <video width="500" height="auto" controls>
                        <source
                            src={video}
                            type="video/mp4"
                            style={{ brightness: "10%" }}
                        />
                    </video>
                </div>
            </div>

            <hr className="my-8 rounded h-8 solid" />
            <h3 className="text-l pt-2 font-semibold w-100 underline">
                Tamagotchi Embedded Game
            </h3>
            <div className="flex">
                <p className="pt-2 w-1/2">
                    The Embedded Virtual Pet system was facilitated by the
                    FRDM-KL46Z ARM board and a standard consumer-grade personal
                    computer. There were no additional hardware components for
                    the implementation, aside from the serial USB connection
                    between the board and computer. Users interface with their
                    virtual pet via a graphical interface that is run on the
                    personal computer. For this project, we used pygame, a
                    simple and open source Python library for creating games.
                    This graphical interface displays the pet (as a sprite) and
                    can be used to display information about the pet. However,
                    the pygame interface is strictly informational, and users
                    are not be able to actually interact with their pet without
                    use of the board. This simple library was chosen so we could
                    focus our attention and project more on the board itself.
                    <br />
                    <br />
                    <a
                        href="https://pages.github.coecis.cornell.edu/ece3140-sp22/dmr284-hkt26-jcp327/"
                        style={{ color: "rgb(59 130 246)" }}
                        rel="noreferrer"
                        target="_blank"
                    >
                        GitHub Pages for CS 3420 Project
                    </a>
                </p>

                <div className="translate-x-20">
                    <iframe
                        width="450"
                        height="400"
                        src="https://www.youtube.com/embed/R5FCstbmDOA"
                        title="3140 Embedded Pet"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>

            <hr className="my-8 rounded h-8 solid" />
            <h3 className="text-l pt-2 font-semibold w-100 underline">
                Pickup Sports Games App
            </h3>
            <div className="flex">
                <p className="pt-2 w-1/2">
                    Currently I am working with a friend on developing an iOS
                    app using Swift that allows users to see and schedule
                    pick-up sports games on their college campus. The app is in
                    development, but we plan on finishing it up over this
                    semester. Ideally, we would like an interface that allows
                    users to login, set up a profile, and see games on a
                    calendar style page. Using Swift and Firebase opened me up
                    to exploring different ways to create apps, ehich is why my
                    next app uses React Native.
                </p>
                <div className="w-1/5 translate-x-40">
                    <img classname="object-scale-down" src={pickup} alt="" />
                </div>
            </div>

            <hr className="my-8 rounded h-8 solid" />
            <h3 className="text-l pt-2 font-semibold w-100 underline">
                React Native Bartender App and API
            </h3>
            <div className="flex">
                <p className="pt-2 w-1/2">
                    Currently, I'm working on a React Native app and a Flask
                    REST API that will be able to ingest a list of ingredients
                    that a user has, requests recipes from the API that match
                    those ingredients, and returns a list of those recipes back
                    to the user. The API is about to be deployed, and it is open
                    source. You can check its progress here!
                </p>
                <div className="w-1/5 translate-x-40">
                    <img classname="object-scale-down" src={tend} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Projects;
