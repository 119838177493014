import React from "react";
import about from "../assets/about.jpg";

function About() {
    return (
        <div
            className="border-4 border-double p-4"
            style={{ width: `calc(100vw - 22rem)` }}
        >
            <h1 className="text-2xl pt-5 font-semibold w-1/2">About me</h1>
            <div className="flex">
                <p className="pt-8 w-1/2">
                    Hi! I'm David Rusnak. I am a Electrical and Computer
                    Engineering student studying at Cornell University.
                    Throughout this website, I showcase the technical skills
                    I've learned, but this page will be about me and my hobbies.{" "}
                    <br />
                    <br /> I grew up in a small ski resort town in New Jersey,
                    so I've been skiing since I was little. During the winter
                    season, I usually go every morning to catch the opening lift
                    before it gets too busy! When I'm not working, I love to
                    play video games, play some music, or watch TV shows. Right
                    now I'm teaching myself the guitar, but I also play piano
                    and violin when I get the chance.
                    <br />
                    <br />
                    I'm looking for roles in either front-end, back-end, or
                    fullstack software engineering. Although most of my
                    internship experience has been front-end, I have learned a
                    lot about systems programming and scripting languages
                    through my courses at Cornell.
                </p>
                <div className="w-1/4 translate-x-40">
                    <img classname="" src={about} alt="" />
                </div>
            </div>
        </div>

        //     <img
        //         className="transform translate-x-4 duration-500"
        //         src=""
        //         alt=""
        //     />
    );
}

export default About;
