import React from "react";

function Contact() {
    return (
        <div
            className="border-4 border-double p-4"
            style={{ width: `calc(100vw - 22rem)` }}
        >
            <h1 className="text-2xl pt-5 font-bold w-1/2">Contact me!</h1>
            <p className="pt-8 w-1/2">
                E-mail me @{" "}
                <a
                    href="mailto:dmr284@cornell.edu"
                    target="_blank"
                    className="text-blue-500"
                >
                    dmr284@cornell.edu
                </a>
            </p>
            <p className="pt-8 w-1/2">
                Check out my work on my{" "}
                <a
                    href="https://github.com/david-rusnak"
                    target="_blank"
                    className="text-blue-500"
                >
                    Github
                </a>
            </p>
            <p className="pt-8 w-1/2">
                Visit my profile on{" "}
                <a
                    href="https://linkedin.com/in/davidmrusnak"
                    target="_blank"
                    className="text-blue-500"
                >
                    LinkedIn
                </a>
            </p>
        </div>
    );
}

export default Contact;
