import React, { useState } from "react";
import background from "./assets/background.png";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Experience from "./components/Experience";
import Contact from "./components/Contact";
import Projects from "./components/Projects";

function App() {
    const [openApp, setOpenApp] = useState(true);

    function updateData(open) {
        setOpenApp(open);
        console.log(openApp);
    }

    return (
        <Router>
            <div
                className={`grid duration-300 h-screen ${
                    !openApp
                        ? "grid-cols-[18rem_auto]"
                        : "grid-cols-[8rem_auto]"
                } `}
            >
                <Sidebar className="h-screen" sendData={updateData}></Sidebar>
                <div className="px-7 py-4">
                    <Routes>
                        <Route
                            className="px-7"
                            path="/"
                            element={<Home />}
                        ></Route>
                        <Route
                            className="px-7"
                            path="/about"
                            element={<About />}
                        ></Route>
                        <Route
                            className="px-7"
                            path="/experience"
                            element={<Experience />}
                        ></Route>
                        <Route
                            className="px-7"
                            path="/projects"
                            element={<Projects />}
                        ></Route>
                        <Route
                            className="px-7"
                            path="/contact"
                            element={<Contact />}
                        ></Route>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
