import React from "react";
import cligif from "../assets/cligif.gif";
import bird from "../assets/bird.png";

function Experience() {
    return (
        <div
            className="border-4 border-double p-4"
            style={{ width: `calc(100vw - 22rem)` }}
        >
            <h1 className="text-2xl pt-5 font-bold w-100">Experience</h1>
            <h2 className="text-xl pt-8 font-semibold w-100 underline">
                Internships
            </h2>
            <p className="pt-2 w-1/2">
                Here I describe some of the internships I have had.
            </p>
            <h3 className="text-l pt-2 font-semibold w-100 underline">
                Cornell Lab of Ornithology
            </h3>
            <div className="flex">
                <ul className="pt-2 w-1/2 px-4 list-disc">
                    <li>
                        Currently, I'm working on porting the eBird
                        identification quiz to Nuxt 2. Originally, I had ported
                        it to Nuxt 3, but we found that for our purposes, Nuxt 2
                        would still be a better choice for now.
                    </li>
                    <li>
                        In this role, I use Vue.js, Storybook.js, and Jest to
                        create stories, tests and documentation for the Lab's
                        component library to ensure bug-free usage.
                    </li>
                    <li>
                        I also have completed projects which use TypeScript and
                        Nuxt to help create new web applications with SSR.
                    </li>
                    <li>
                        I completed a project in which I created design tokens
                        for SASS files to use Figma design files as the single
                        source of truth for design elements across lab
                        applications.
                    </li>
                </ul>
                <div className="w-1/2">
                    <img src={bird} alt="bird" />
                </div>
            </div>

            <hr className="my-8 rounded h-8 solid" />
            <h3 className="text-l font-semibold w-100 underline">InfluxData</h3>
            <div className="flex">
                <ul className="pt-2 w-1/2 px-4 list-disc">
                    <li>
                        In this role, I used React and TypeScript to maintain
                        and create new features for the InfluxData Cloud 2 UI.
                    </li>
                    <li>
                        I employed unit testing and end to end testing to ensure
                        components were bug-free using Jest and Cypress.
                    </li>
                    <li>
                        Halfway through the internship, I participated in a week
                        long hackathon to introduce a new 3D globe data
                        visualization into the UI using an opensource WebGL
                        library.
                    </li>
                    <li>Blog post about the CLI onboarding here!</li>
                </ul>
                <div className="w-1/2">
                    <img src={cligif} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Experience;
