import React from "react";
import { BsCloudArrowDown } from "react-icons/bs";

function Home() {
    return (
        <div
            className="border-4 border-double p-4"
            style={{ width: `calc(100vw - 22rem)` }}
        >
            <h1 className="text-2xl pt-5 font-semibold w-1/2">
                Hi, I'm David!
            </h1>
            <p className="pt-8 w-1/2">
                I'm a student at Cornell University and I made this website
                originally in an engineering communications course I took.
                However, since then, I have been adding and updating it to
                practice my skills with React and to always have something that
                I can continuously design.
            </p>
        </div>

        //     <img
        //         className="transform translate-x-4 duration-500"
        //         src=""
        //         alt=""
        //     />
    );
}

export default Home;
