import { BsArrowLeftShort, BsArrowRightShort, BsSearch } from "react-icons/bs";
import React, { useState } from "react";
import logoDark from "../assets/logodark.png";
import { useNavigate } from "react-router-dom";

function Sidebar(props) {
    const [open, setOpen] = useState(false);

    const searchFlag = false;

    const navigate = useNavigate();

    const menuItems = [
        { title: "About Me", route: "/about" },
        { title: "Experience", route: "/experience" },
        { title: "Projects", route: "/projects" },
        { title: "Contact Me", route: "/contact" },
    ];

    return (
        <div
            className={`bg-black p-5 pt-8 transition-width ${
                open ? "w-72" : "w-32"
            } relative`}
        >
            {open && (
                <BsArrowLeftShort
                    className="bg-white text-black text-3xl rounded-full absolute -right-3 top-14 border border-black cursor-pointer "
                    onClick={() => {
                        setOpen(!open);
                        props.sendData(open);
                    }}
                ></BsArrowLeftShort>
            )}

            {!open && (
                <BsArrowRightShort
                    className="bg-white text-black text-3xl rounded-full absolute -right-3 top-14 border border-black cursor-pointer transition-width"
                    onClick={() => {
                        setOpen(!open);
                        props.sendData(open);
                    }}
                ></BsArrowRightShort>
            )}
            <div className="inline-flex">
                <img
                    src={logoDark}
                    alt=""
                    className={`text-4xl rounded cursor-pointer block float-left mr-2 w-20 h-auto absolute top-9 duration-500 ${
                        open && "rotate-[360deg]"
                    }`}
                    onClick={() => navigate("/")}
                />
                <span
                    className={`text-white origin-center whitespace-nowrap font-medium text-2xl duration-300 absolute right-8 top-14 cursor-pointer ${
                        !open && "scale-0"
                    }`}
                    onClick={() => navigate("/")}
                    style={{ fontFamily: "Avenirnext, sans-serif" }}
                >
                    David Rusnak
                </span>
            </div>
            {searchFlag && (
                <div
                    className={`flex items-center mt-16 py-2 px-2 rounded-md duration-300 ${
                        open && "bg-light-white"
                    } ${!open && "bg-black"} ${!open ? "px-8" : "px-2.5"}`}
                >
                    <BsSearch
                        className={`text-white text-lg block float-left cursor-pointer mr-2`}
                        onClick={() => {
                            setOpen(!open);
                            props.sendData(open);
                        }}
                    ></BsSearch>
                    <input
                        type={"search"}
                        placeholder="Search"
                        className={`text-base bg-transparent w-full text-white focus:outline-none ${
                            !open && "hidden"
                        }`}
                    />
                </div>
            )}

            <ul className="pt-16">
                {menuItems.map((menu, index) => (
                    <>
                        <li
                            key={index}
                            className={`text-white text-sm flex font-medium items-center gap-x-4 cursor-pointer whitespace-nowrap duration-500 p-2 ${
                                !open && "hidden"
                            }`}
                        >
                            <span
                                onClick={() => {
                                    navigate(menu.route);
                                }}
                            >
                                {menu.title}
                            </span>
                        </li>
                    </>
                ))}
            </ul>
        </div>
    );
}

export default Sidebar;
